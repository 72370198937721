import { AnimatePresence, Box, Container, Flex, HtmlContent, IconClose, motion } from 'designsystem';
import { useTheme } from '@emotion/react';
import { FC, useCallback, useEffect, useState } from 'react';

const HomeAlert: FC<{ html?: string }> = ({ html }) => {
    const theme = useTheme();
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && window.sessionStorage.getItem('hide-home-alert') !== 'true') {
            setIsShown(true);
        }
    }, []);

    const onClose = useCallback(() => {
        setIsShown(false);
        window.sessionStorage.setItem('hide-home-alert', 'true');
    }, []);

    if (!html?.trim()) {
        return null;
    }

    return (
        <AnimatePresence>
            {isShown && (
                <MotionBox
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0, scaleY: 0 }}
                    bg={theme.tokens.SyntaxBackgroundPrimaryDefault}
                    transformOrigin="top"
                >
                    <Container p={4} sx={{ a: { color: 'inherit !important' } }} textAlign="center">
                        <Flex gap={2}>
                            <Box flex={1}>
                                <HtmlContent html={html} />
                            </Box>
                            <Box as="button" w={6} h={6} onClick={onClose}>
                                <IconClose color="black" />
                            </Box>
                        </Flex>
                    </Container>
                </MotionBox>
            )}
        </AnimatePresence>
    );
};
const MotionBox = motion(Box);

export default HomeAlert;
