import { FC, useEffect } from 'react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import { fetchCraftData, gtm } from 'shared';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { HomeQuery, HomeQueryVariables, useHomeQuery } from '../gql/cms';
import loadIntlMessages from '../utils/loadIntlMessages';
import { useRouter } from 'next/router';
import Error from 'next/error';
import HomeFestivalBlocks from '../components/HomeFestivalBlocks';
import HomeHero from '../components/HomeHero';
import HomeAlert from '../components/HomeAlert';
import { VStack } from 'designsystem';
import prefetchGlobals from '../utils/prefetchGlobals';
import { DEFAULT_REVALIDATION_TIME } from './[...uri]';
import prefetchFestivalBlocks from '../utils/prefetchFestivalBlocks';

const Home: FC = () => {
    const { locale } = useRouter();
    const { data } = useHomeQuery({ site: [siteHandleByLocale[locale]] });
    const entry = data?.entry;

    useEffect(() => {
        gtm.pageView('/', {
            page_pillar: 'festival',
            page_language: locale,
        });
    }, [locale]);

    if (entry?.__typename !== 'festivalHomepage_festivalHomepage_Entry') {
        return <Error statusCode={500} title="No homepage entry found in craft" />;
    }

    return (
        <>
            <HomeHero festivalHomeEntry={data.entry} />
            <HomeAlert html={entry.homeAlert} />
            <VStack py="17" spacing="17">
                {entry.homeFestivalBlocks && <HomeFestivalBlocks homeFestivalBlocks={entry.homeFestivalBlocks} />}
            </VStack>
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const vars = { site: [siteHandleByLocale[locale]] };

    const data = await queryClient.fetchQuery(useHomeQuery.getKey(vars), () =>
        fetchCraftData<HomeQuery, HomeQueryVariables>({
            query: useHomeQuery.document,
            variables: vars,
        })
    );
    await prefetchGlobals(queryClient, locale);
    if (data.entry.__typename === 'festivalHomepage_festivalHomepage_Entry') {
        await prefetchFestivalBlocks(queryClient, data.entry.homeFestivalBlocks, locale);
    }

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};
export default Home;
