/**
 * TODO: Find a way to move some of this logic to the shared package
 */

import {
    Body,
    BoxProps,
    VideoSection,
    Cta,
    getColorSchemeFromString,
    IconCaret,
    IconExternalLink,
    ImpressionsCarousel,
    LinkCarousel,
    Stack,
    TextButton,
    Wrapper,
} from 'designsystem';
import React, { FC, ReactElement, useMemo } from 'react';
import { useGetCmsImageProps } from 'shared';
import { HomeQuery } from '../gql/cms';
import HomeDestinationListBlock from './HomeDestinationListBlock';
import HomeNewsletter from './HomeNewsletter';
import ContentBlockListGroup from './ContentBlockListGroup';
import { FormattedMessage } from 'react-intl';
import Longread from './Longread';
import HomeRecentNews from './HomeRecentNews';

export type HomeFestivalBlocksData = Extract<
    HomeQuery['entry'],
    { __typename: 'festivalHomepage_festivalHomepage_Entry' }
>['homeFestivalBlocks'];

export type HomeFestivalBlock = HomeFestivalBlocksData[number];

type StylesPerHomeFestivalBlockType = Partial<Record<HomeFestivalBlock['__typename'] | 'default', BoxProps>>;

type OverrideComponentForHomeFestivalBlock = Partial<
    Record<
        HomeFestivalBlock['__typename'] | string,
        (homeFestivalBlockData: HomeFestivalBlock, styles: BoxProps) => ReactElement
    >
>;

const stylesForBlockType = (
    type: keyof StylesPerHomeFestivalBlockType,
    stylesPerHomeFestivalBlockType: StylesPerHomeFestivalBlockType
) => stylesPerHomeFestivalBlockType[type] || stylesPerHomeFestivalBlockType.default || {};

export const componentForHomeFestivalBlock = ({
    homeFestivalBlock,
    stylesPerHomeFestivalBlockType,
    override,
    getCmsImageProps,
}: {
    homeFestivalBlock: HomeFestivalBlock;
    stylesPerHomeFestivalBlockType: StylesPerHomeFestivalBlockType;
    override: OverrideComponentForHomeFestivalBlock;
    getCmsImageProps: ReturnType<typeof useGetCmsImageProps>;
}) => {
    const styles = { ...stylesForBlockType(homeFestivalBlock.__typename, stylesPerHomeFestivalBlockType) };

    if (override[homeFestivalBlock.__typename]) {
        return override[homeFestivalBlock.__typename](homeFestivalBlock, styles);
    }

    switch (homeFestivalBlock.__typename) {
        case 'homeFestivalBlocks_cta_BlockType':
            return (
                <Wrapper key={homeFestivalBlock.id}>
                    <Cta
                        inverted
                        colorScheme={getColorSchemeFromString(homeFestivalBlock.color)}
                        title={homeFestivalBlock.heading}
                        image={getCmsImageProps(homeFestivalBlock.image?.[0])}
                    >
                        <Body>{homeFestivalBlock.description}</Body>
                        <Stack spacing={3}>
                            {homeFestivalBlock.links?.map(
                                l =>
                                    l.linkObject.url && (
                                        <TextButton
                                            key={l.id}
                                            href={l.linkObject.url}
                                            target={l.linkObject.type === 'url' && '_blank'}
                                            size="s"
                                            as="div"
                                            iconRight={
                                                l.linkObject.type === 'url' ? <IconExternalLink /> : <IconCaret />
                                            }
                                        >
                                            {l.label}
                                        </TextButton>
                                    )
                            )}
                        </Stack>
                    </Cta>
                </Wrapper>
            );
            return null;
        case 'homeFestivalBlocks_destinationList_BlockType':
            return <HomeDestinationListBlock key={homeFestivalBlock.id} destinations={homeFestivalBlock.destination} />;
        case 'homeFestivalBlocks_festivalImpressions_BlockType':
            return (
                <ImpressionsCarousel
                    key={homeFestivalBlock.id}
                    slides={homeFestivalBlock.images.map(image => ({
                        key: image.id,
                        image: getCmsImageProps(image, image.alt, `(min-width: 1200px) 2400px, 100vw`),
                    }))}
                    title={homeFestivalBlock.heading}
                    description={homeFestivalBlock.text}
                    navigationTitle={homeFestivalBlock.navigationLabel}
                    buttons={
                        homeFestivalBlock.ctaLink && (
                            <TextButton
                                href={homeFestivalBlock.ctaLink.url}
                                target={homeFestivalBlock.ctaLink.type === 'url' && '_blank'}
                                size="s"
                                iconRight={
                                    homeFestivalBlock.ctaLink.type === 'url' ? <IconExternalLink /> : <IconCaret />
                                }
                            >
                                {homeFestivalBlock.ctaLabel}
                            </TextButton>
                        )
                    }
                />
            );
        case 'homeFestivalBlocks_linkCarousel_BlockType':
            return (
                <Wrapper key={homeFestivalBlock.id}>
                    <LinkCarousel
                        title={homeFestivalBlock.heading}
                        body={homeFestivalBlock.text}
                        textButton={
                            homeFestivalBlock.ctaLink.url && (
                                <TextButton
                                    href={homeFestivalBlock.ctaLink.url}
                                    title={homeFestivalBlock.ctaLink.text}
                                    target={homeFestivalBlock.ctaLink.type === 'url' && '_blank'}
                                    size="s"
                                    iconRight={
                                        homeFestivalBlock.ctaLink.type === 'url' ? <IconExternalLink /> : <IconCaret />
                                    }
                                >
                                    {homeFestivalBlock.ctaText}
                                </TextButton>
                            )
                        }
                        slides={homeFestivalBlock.carousel.map(slide => ({
                            ...getCmsImageProps(slide.image[0]),
                            title: slide.label,
                            subtitle: slide.title,
                            href: slide.linkObject.url || '#',
                            id: slide.id,
                            target: slide.linkObject.type === 'url' && '_blank',
                            iconRight: slide.linkObject.type === 'url' ? <IconExternalLink /> : <IconCaret />,
                        }))}
                    />
                </Wrapper>
            );
        case 'homeFestivalBlocks_listGroups_BlockType':
            return (
                <ContentBlockListGroup
                    listGroups={homeFestivalBlock.listGroup}
                    fallback={{
                        image: getCmsImageProps(homeFestivalBlock.fillerImage[0]),
                        label: homeFestivalBlock.fillerImageLabel,
                        url: homeFestivalBlock.fillerImageLink.url,
                    }}
                />
            );
        case 'homeFestivalBlocks_longread_BlockType':
            return <Longread longread={{ ...homeFestivalBlock }} />;
        case 'homeFestivalBlocks_newsletterSignup_BlockType':
            return <HomeNewsletter title={homeFestivalBlock.heading} description={homeFestivalBlock.subtitle} />;
        case 'homeFestivalBlocks_video_BlockType':
            return (
                <VideoSection
                    w="100%"
                    video={{ url: homeFestivalBlock.videoLink }}
                    label={<FormattedMessage defaultMessage="Play trailer" />}
                />
            );
        case 'homeFestivalBlocks_recentNews_BlockType':
            return <HomeRecentNews amount={homeFestivalBlock.amount} />;
        default:
            return null;
    }
};

interface Props {
    homeFestivalBlocks: HomeFestivalBlocksData;
    stylesPerHomeFestivalBlockType?: StylesPerHomeFestivalBlockType;
    override?: OverrideComponentForHomeFestivalBlock;
}

export const componentsForHomeFestivalBlocks = ({
    homeFestivalBlocks,
    stylesPerHomeFestivalBlockType = {},
    override = {},
    getCmsImageProps,
}: Props & { getCmsImageProps: ReturnType<typeof useGetCmsImageProps> }) =>
    homeFestivalBlocks?.map(homeFestivalBlock =>
        componentForHomeFestivalBlock({ homeFestivalBlock, stylesPerHomeFestivalBlockType, override, getCmsImageProps })
    );

const HomeFestivalBlocks: FC<Props> = ({ homeFestivalBlocks, stylesPerHomeFestivalBlockType = {}, override = {} }) => {
    const getCmsImageProps = useGetCmsImageProps();

    const components = useMemo(
        () =>
            componentsForHomeFestivalBlocks({
                homeFestivalBlocks,
                stylesPerHomeFestivalBlockType,
                override,
                getCmsImageProps,
            }) || [],
        [homeFestivalBlocks, getCmsImageProps, override, stylesPerHomeFestivalBlockType]
    );
    return <>{components.map(component => component)}</>;
};

export default HomeFestivalBlocks;
