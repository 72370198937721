import { ComponentProps, FC } from 'react';
import { Newsletter, Wrapper } from 'designsystem';
import { useIntl } from 'react-intl';
import { getNewsletterFormProps } from 'shared';

const HomeNewsletter: FC<Pick<ComponentProps<typeof Newsletter>, 'title' | 'description'>> = ({
    title,
    description,
}) => {
    const intl = useIntl();
    return (
        <Wrapper>
            <Newsletter
                title={title}
                description={description}
                placeholder={intl.formatMessage({ defaultMessage: 'Vul je e-mail adres in' })}
                buttonLabel={intl.formatMessage({ defaultMessage: 'Verstuur' })}
                formProps={getNewsletterFormProps(intl.locale)}
            />
        </Wrapper>
    );
};

export default HomeNewsletter;
