import { Heading, Stack, HStack, Box, Body, Flex, Tile, TextButton, ColorScheme, Img, AspectRatio } from 'designsystem';
import { FC } from 'react';
import { ListGroupFragment } from '../gql/cms';
import { useGetCmsImageProps } from 'shared';
import Link from 'next/link';
import { useTheme } from '@emotion/react';

interface Props {
    listGroup: ListGroupFragment;
    primary?: boolean;
    colorScheme?: ColorScheme;
}

const ListGroup: FC<Props> = ({ listGroup, primary = true, colorScheme = 'festival' }) => {
    const getImgProps = useGetCmsImageProps();
    const theme = useTheme();
    return (
        <Tile
            colorScheme={colorScheme}
            primary={primary}
            justifyContent="center"
            px={[6, null, null, 15]}
            py={[9, null, null, 15]}
        >
            <Flex w="100%">
                <Stack spacing={7} w="100%">
                    <Heading as="h2" variant={2} mb={[0, null, null, 6]}>
                        {listGroup.heading}
                    </Heading>
                    <Stack spacing={7}>
                        {listGroup.listItems.map((item, i) => (
                            <Link
                                key={item.id}
                                href={item.linkObject.url || ''}
                                target={item.linkObject.type === 'url' && '_blank'}
                            >
                                <HStack spacing={6} alignItems="center">
                                    {item.image?.length > 0 ? (
                                        <AspectRatio ratio={1 / 1} w={[9, null, null, 13]} h={[9, null, null, 13]}>
                                            <Img
                                                {...getImgProps(item.image[0])}
                                                fill
                                                width={80}
                                                height={80}
                                                borderRadius="100%"
                                            />
                                        </AspectRatio>
                                    ) : (
                                        <Flex
                                            bg={theme.tokens.ColorNeutralBlack}
                                            minW={[9, null, null, 13]}
                                            h={[9, null, null, 13]}
                                            borderRadius="100%"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Body color="yellow.60" fontWeight={700} fontSize={[4, null, null, 6]}>
                                                {i + 1}
                                            </Body>
                                        </Flex>
                                    )}

                                    <Box>
                                        <Heading variant={4}>{item.text}</Heading>
                                        <Body>{item.subtext}</Body>
                                    </Box>
                                </HStack>
                            </Link>
                        ))}
                    </Stack>
                    {listGroup.bottomCtaLabel && (
                        <TextButton
                            href={listGroup.bottomCtaLink.url ?? ''}
                            target={listGroup.bottomCtaLink.type === 'url' && '_blank'}
                        >
                            <Body>{listGroup.bottomCtaLabel}</Body>
                        </TextButton>
                    )}
                </Stack>
            </Flex>
        </Tile>
    );
};

export default ListGroup;
