import {
    DestinationListItem,
    Flex,
    Grid,
    IconCaret,
    IconExternalLink,
    ListDivider,
    Stack,
    Wrapper,
} from 'designsystem';
import Link from 'next/link';
import { SponsorsBanner } from 'shared';
import { DestinationFragment } from '../gql/cms';

interface Props {
    destinations: DestinationFragment[];
}

const HomeDestinationListBlock: React.FC<Props> = ({ destinations }) => (
    <Wrapper>
        <Grid>
            <Flex gridColumn={['1 / -1', null, '1 / span 5']}>
                <Stack spacing={0} divider={<ListDivider />} w="100%">
                    {destinations?.map(destination => (
                        <Link
                            href={destination.linkTo.url || '/404'}
                            key={destination.id}
                            target={destination.linkTo.type === 'url' && '_blank'}
                        >
                            <DestinationListItem
                                title={destination.heading}
                                textButtonProps={{
                                    children: destination.label,
                                    iconRight: destination.linkTo.type === 'url' ? <IconExternalLink /> : <IconCaret />,
                                }}
                            />
                        </Link>
                    ))}
                </Stack>
            </Flex>
            <Flex gridColumn={['1 / -1', null, '9 / -1']} alignItems="flex-end" flexDirection="column">
                <SponsorsBanner maxW="368px" />
            </Flex>
        </Grid>
    </Wrapper>
);

export default HomeDestinationListBlock;
