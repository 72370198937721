import { Body, Box, Button, Flex, Heading, Img, TextButton, VStack, Wrapper } from 'designsystem';
import { FC } from 'react';
import { LongreadFragment } from '../gql/cms';
import { SponsorsBanner, useGetCmsImageProps } from 'shared';
import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import Link from 'next/link';

interface Props {
    longread: LongreadFragment;
}

const Longread: FC<Props> = ({ longread }) => {
    const getImgProps = useGetCmsImageProps();
    const theme = useTheme();

    return (
        <Wrapper my={17}>
            <Flex justifyContent="space-between" flexDir={['column', null, null, 'row']} w="100%">
                <VStack spacing={6} alignItems="start" flex={1}>
                    <Heading variant={3}>{longread.heading}</Heading>
                    <Body>{longread.text}</Body>
                    {longread.ctaType === 'button' && (
                        <Button
                            as="a"
                            href={longread.ctaLink.url ?? ''}
                            color={theme.tokens.ColorNeutralWhite}
                            bgColor={theme.tokens.ColorNeutralBlack}
                            target={longread.ctaLink?.type === 'url' ? '_blank' : '_self'}
                        >
                            {longread.ctaText}
                        </Button>
                    )}
                    {longread.ctaType === 'inlineText' && (
                        <TextButton as={Link} href={longread.ctaLink.url ?? ''}>
                            <Body>{longread.ctaText}</Body>
                        </TextButton>
                    )}
                </VStack>
                <VStack flex={1} mt={[17, null, null, 0]}>
                    <Box ml="auto">
                        {longread.image?.length > 0 ? (
                            <>
                                <Img {...getImgProps(longread.image[0])} />
                                {longread.imageCaption && (
                                    <Body fontSize={2} mt={[2, null, null, 4]}>
                                        {longread.imageCaption}
                                    </Body>
                                )}
                            </>
                        ) : (
                            <>
                                <SponsorsBanner minW="320px" />
                                <Body fontSize={2} mt={4}>
                                    <FormattedMessage defaultMessage="Mede mogelijk gemaakt door" />
                                </Body>
                            </>
                        )}
                    </Box>
                </VStack>
            </Flex>
        </Wrapper>
    );
};

export default Longread;
