import { Button, FestivalHero, TextButton } from 'designsystem';
import Link from 'next/link';
import { useGetCmsImageProps, useUserRoles } from 'shared';
import { HomeQuery } from '../gql/cms';

interface Props {
    festivalHomeEntry: HomeQuery['entry'];
}

type FestivalHomeQueryEntry = Extract<HomeQuery['entry'], { __typename: 'festivalHomepage_festivalHomepage_Entry' }>;

interface HeroVariantProps {
    hero:
        | FestivalHomeQueryEntry['homeHeroFestivalPublic'][number]
        | FestivalHomeQueryEntry['homeHeroFestivalProfessionals'][number];
}

const HomeHero: React.FC<Props> = ({ festivalHomeEntry }) => {
    const { hasProfessionalsAccess } = useUserRoles();
    if (festivalHomeEntry.__typename !== 'festivalHomepage_festivalHomepage_Entry') {
        return null;
    }
    return (
        <HomeHeroVariant
            hero={
                hasProfessionalsAccess
                    ? festivalHomeEntry.homeHeroFestivalProfessionals[0]
                    : festivalHomeEntry.homeHeroFestivalPublic[0]
            }
        />
    );
};

const HomeHeroVariant: React.FC<HeroVariantProps> = ({ hero }) => {
    const getCmsImageProps = useGetCmsImageProps();
    if (
        hero?.__typename === 'homeHeroFestivalPublic_splitScreenHero_BlockType' ||
        hero?.__typename === 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'
    ) {
        return (
            <FestivalHero
                variant="split"
                live={false}
                buttons={hero.links.map(
                    link =>
                        link?.uri && (
                            <TextButton as={Link} href={link.uri} key={link.id} size="s">
                                {link.label}
                            </TextButton>
                        )
                )}
                title={hero.heroTitle}
                subTitle={hero.subtitle}
                images={hero.image.map(image => ({
                    image: getCmsImageProps(image),
                    key: image.id,
                    title: image.title,
                }))}
                video={hero.video}
            />
        );
    }
    if (
        hero?.__typename === 'homeHeroFestivalPublic_wideImageHero_BlockType' ||
        hero?.__typename === 'homeHeroFestivalProfessionals_wideImageHero_BlockType'
    ) {
        return (
            <FestivalHero
                variant="default"
                buttons={[
                    hero.mainCtaLink?.url && (
                        <Link
                            href={hero.mainCtaLink.url}
                            title={hero.mainCtaLink.text}
                            target={hero.mainCtaLink.type === 'url' && '_blank'}
                        >
                            <Button variant="solidOnDark">{hero.mainCtaLabel}</Button>
                        </Link>
                    ),
                    hero.secondaryCtaLink?.url && (
                        <Link
                            href={hero.secondaryCtaLink.url}
                            title={hero.secondaryCtaLink.text}
                            target={hero.secondaryCtaLink.type === 'url' && '_blank'}
                        >
                            <Button variant="outline">{hero.secondaryCtaLabel}</Button>
                        </Link>
                    ),
                ]}
                live={!!hero.redDotText}
                title={hero.heroTitle}
                subTitle={hero.redDotText}
                images={hero.images.map(image => ({
                    image: getCmsImageProps(image, image.title, '100vw'),
                    key: image.id,
                    title: image.title,
                }))}
                video={hero.video}
            />
        );
    }
    return undefined;
};

export default HomeHero;
