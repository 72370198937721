import { Flex, Box, AspectRatio, Img, ImgProps, Body } from 'designsystem';
import { FC } from 'react';
import ListGroup from './ListGroup';
import { ListGroupFragment } from '../gql/cms';
import Link from 'next/link';
import { useTheme } from '@emotion/react';

interface Props {
    listGroups: ListGroupFragment[];
    fallback: { image: ImgProps; label?: string; url?: string };
}

const ContentBlockListGroup: FC<Props> = ({ listGroups, fallback }) => {
    const [firstListGroup, secondListGroup] = listGroups;
    const hasTwoListGroups = !!firstListGroup && !!secondListGroup;
    const { tokens } = useTheme();

    return (
        <Flex flexDir={['column-reverse', null, null, 'row']} w="100%">
            {hasTwoListGroups ? (
                <Box
                    bg="neutral.10"
                    flex={1}
                    width={['100%', null, null, `${(3 / 6) * 100}%`]}
                    mt={[0, null, null, '112px']}
                >
                    <ListGroup listGroup={firstListGroup} primary={false} />
                </Box>
            ) : (
                <Box
                    as={fallback.url ? Link : 'div'}
                    width={['100%', null, null, `${(3 / 6) * 100}%`]}
                    mt={[0, null, null, '112px']}
                    flex={1}
                    position="relative"
                    {...(fallback.url ? { href: fallback.url } : {})}
                >
                    <AspectRatio w="100%" ratio={1} h="100%">
                        <Img style={{ objectFit: 'cover', height: '100%' }} fill {...fallback.image} />
                    </AspectRatio>
                    {fallback.label && (
                        <Box
                            position="absolute"
                            display="flex"
                            alignItems="flex-end"
                            inset="0"
                            padding={[6, null, 8]}
                            color={tokens.SyntaxTextColorOnDark}
                            bgGradient={`linear(to-b, rgba(0,0,0,0), ${tokens.SyntaxOverlayColorDefault}) 83%`}
                        >
                            <Body>{fallback.label}</Body>
                        </Box>
                    )}
                </Box>
            )}

            {(secondListGroup ?? firstListGroup) && (
                <Box width={['100%', null, null, `${(3 / 6) * 100}%`]} flex={1}>
                    <ListGroup listGroup={secondListGroup ?? firstListGroup} />
                </Box>
            )}
        </Flex>
    );
};

export default ContentBlockListGroup;
